import { createSlice } from "@reduxjs/toolkit"

export const videoSlice = createSlice({
  name: "video",
  initialState: {
    videoList: [], 
    categoryList: [],   
    exportList: [],  
    videoDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setVideoList: (state, action) => {
      state.videoList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.videoDetail = action.payload
    }

  }
})

export const {
  setVideoList,
  setFreshList,
  setExportList, 
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = videoSlice.actions

export const selectVideoList = (state) => state.video.videoList
export const selectFreshData = (state) => state.video.isFreshData
export const selectCategoryList = (state) => state.video.categoryList
export const selectExportList = (state) => state.video.exportList
export const selectFilterTerm = (state) => state.video.filterTerm
export const selectTotalEntry = (state) => state.video.totalEntry
export const selectVideoDetail = (state) => state.video.packageDetail

export default videoSlice.reducer
 