import { createSlice } from "@reduxjs/toolkit"

export const treeSlice = createSlice({
  name: "tree",
  initialState: {
    treeList: [], 
    treeDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setTreeList: (state, action) => { 
      state.treeList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.treeDetail = action.payload
    }

  }
})

export const {
  setTreeList,
  setFreshList,
  setExportList,
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = treeSlice.actions

export const selectTreeList = (state) => state.tree.treeList
export const selectFreshData = (state) => state.tree.isFreshData
export const selectFilterTerm = (state) => state.tree.filterTerm
export const selectTotalEntry = (state) => state.tree.totalEntry
export const selectTreeDetail = (state) => state.tree.treeDetail

export default treeSlice.reducer
 