// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import users from "./slices/userSlice"
import auth from "./slices/authSlice"
import banner from "./slices/bannerSlice"
import packageSubscription from "./slices/packageSlice"
import history from "./slices/historySlice"
import caseStudy from "./slices/casestudySlice"
import event from "./slices/eventSlice"
import video from "./slices/videoSlice"
import announcements from "./slices/announcementsSlice"
import donation from "./slices/donationSlice"
import string from "./slices/stringSlice"
import condition from "./slices/conditionSlice"
import tree from "./slices/treeSlice"
import coinhistory from './slices/coinhistorySlice'
import news from './slices/newsSlice'
import fact from './slices/factSlice'
import treedata from './slices/treedataSlice'
import notice from './slices/noticeSlice'
import mcoin from './slices/mcoinSlice'
import dashboard from '../components/../redux/slices/dashboardSlice'
import otp from './slices/otpVerfication/otpSlice'

const rootReducer = { navbar, layout, users, auth, banner, packageSubscription, history, caseStudy, event, video, announcements, donation, string, condition, tree, coinhistory, news, fact, treedata, notice, mcoin, dashboard, otp }

export default rootReducer
 