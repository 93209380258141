import { createSlice } from "@reduxjs/toolkit"

export const casestudySlice = createSlice({
  name: "caseStudy",
  initialState: {
    casestudyList: [], 
    categoryList: [],   
    exportList: [],  
    casestudyDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setCasestudyList: (state, action) => {
      state.casestudyList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.casestudyDetail = action.payload
    }

  }
})

export const {
  setCasestudyList,
  setFreshList,
  setExportList, 
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = casestudySlice.actions

export const selectCasestudyList = (state) => state.caseStudy.casestudyList
export const selectFreshData = (state) => state.caseStudy.isFreshData
export const selectCategoryList = (state) => state.caseStudy.categoryList
export const selectExportList = (state) => state.caseStudy.exportList
export const selectFilterTerm = (state) => state.caseStudy.filterTerm
export const selectTotalEntry = (state) => state.caseStudy.totalEntry
export const selectCasestudyDetail = (state) => state.caseStudy.packageDetail

export default casestudySlice.reducer
 