import { createSlice } from "@reduxjs/toolkit"

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState: {
    announcementsList: [], 
    categoryList: [],   
    exportList: [],  
    announcementsDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setAnnouncementsList: (state, action) => {
      state.announcementsList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.announcementsDetail = action.payload
    }

  }
})

export const {
  setAnnouncementsList,
  setFreshList,
  setExportList, 
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = announcementsSlice.actions

export const selectAnnouncementsList = (state) => state.announcements.announcementsList
export const selectFreshData = (state) => state.announcements.isFreshData
export const selectCategoryList = (state) => state.announcements.categoryList
export const selectExportList = (state) => state.announcements.exportList
export const selectFilterTerm = (state) => state.announcements.filterTerm
export const selectTotalEntry = (state) => state.announcements.totalEntry
export const selectAnnouncementsDetail = (state) => state.announcements.announcementsDetail

export default announcementsSlice.reducer
 