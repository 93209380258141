import { createSlice } from "@reduxjs/toolkit"

export const coinhistorySlice = createSlice({
  name: "coinhistory",
  initialState: {
    coinhistoryList: [],     
    coinhistoryDetail: {},  
    totalEntry: 0,   
    filterTerm: 'Active'   
  }, 
  reducers: {
    setCoinhistoryList: (state, action) => {
      state.coinhistoryList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.coinhistoryDetail = action.payload
    }

  }
}) 

export const {
  setCoinhistoryList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = coinhistorySlice.actions

export const selectCoinhistoryList = (state) => state.coinhistory.coinhistoryList
export const selectExportList = (state) => state.coinhistory.exportList
export const selectFilterTerm = (state) => state.coinhistory.filterTerm
export const selectTotalEntry = (state) => state.coinhistory.totalEntry
export const selectCoinhistoryDetail = (state) => state.coinhistory.coinhistoryDetail

export default coinhistorySlice.reducer
