import { createSlice } from "@reduxjs/toolkit"

export const historySlice = createSlice({
  name: "history",
  initialState: {
    historyList: [],    
    exportList: [],  
    historyDetail: {},  
    totalEntry: 0,   
    filterTerm: 'Active'   
  }, 
  reducers: {
    setHistoryList: (state, action) => {
      state.historyList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.historyDetail = action.payload
    }

  }
}) 

export const {
  setHistoryList,
  setExportList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = historySlice.actions

export const selectHistoryList = (state) => state.history.historyList
export const selectExportList = (state) => state.history.exportList
export const selectFilterTerm = (state) => state.history.filterTerm
export const selectTotalEntry = (state) => state.history.totalEntry
export const selectHistoryDetail = (state) => state.history.historyDetail

export default historySlice.reducer
