import { createSlice } from "@reduxjs/toolkit"

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: { 
    dashboardList: [],  
    dashboardDetail: {},  
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setDashboardList: (state, action) => { 
      state.dashboardList = action.payload
    },
    setDashboardDetail: (state, action) => {
      state.dashboardDetail = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    }

  }
})

export const {
  setDashboardList,
  setDashboardDetail,
  setTotalEntry,
  setFilteredList
} = dashboardSlice.actions

export const selectDashboardList = (state) => state.dashboard.dashboardList
export const selectDashboardDetail = (state) => state.dashboard.dashboardDetail
export const selectFilterTerm = (state) => state.dashboard.filterTerm
export const selectTotalEntry = (state) => state.dashboard.totalEntry

export default dashboardSlice.reducer
 