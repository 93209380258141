import { createSlice } from "@reduxjs/toolkit"

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    newsList: [], 
    categoryList: [],   
    exportList: [],  
    newsDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setNewsList: (state, action) => { 
      state.newsList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.newsDetail = action.payload
    }

  }
})

export const {
  setNewsList,
  setFreshList,
  setExportList,
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = newsSlice.actions

export const selectNewsList = (state) => state.news.newsList
export const selectFreshData = (state) => state.news.isFreshData
export const selectCategoryList = (state) => state.news.categoryList
export const selectExportList = (state) => state.news.exportList
export const selectFilterTerm = (state) => state.news.filterTerm
export const selectTotalEntry = (state) => state.news.totalEntry
export const selectNewsDetail = (state) => state.news.eventDetail

export default newsSlice.reducer
 