import { createSlice } from "@reduxjs/toolkit"

export const factSlice = createSlice({
  name: "fact",
  initialState: {
    factList: [],  
    factDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setFactList: (state, action) => { 
      state.factList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.factDetail = action.payload
    }

  }
})

export const {
  setFactList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = factSlice.actions

export const selectFactList = (state) => state.fact.factList
export const selectFreshData = (state) => state.fact.isFreshData
export const selectCategoryList = (state) => state.fact.categoryList
export const selectExportList = (state) => state.fact.exportList
export const selectFilterTerm = (state) => state.fact.filterTerm
export const selectTotalEntry = (state) => state.fact.totalEntry
export const selectFactDetail = (state) => state.fact.eventDetail

export default factSlice.reducer
 