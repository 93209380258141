import { createSlice } from "@reduxjs/toolkit"

export const conditionSlice = createSlice({
  name: "condition",
  initialState: {
    conditionList: [], 
    conditionDetail: {},  
    isFreshData: false,
    totalEntry: 0,    
    filterTerm: 'Active'  
  }, 
  reducers: {
    setConditionList: (state, action) => {
      state.conditionList = action.payload
    },  
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.conditionDetail = action.payload
    }

  }
})

export const {
  setConditionList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = conditionSlice.actions

export const selectConditionList = (state) => state.condition.conditionList
export const selectFreshData = (state) => state.condition.isFreshData
export const selectExportList = (state) => state.condition.exportList
export const selectFilterTerm = (state) => state.condition.filterTerm
export const selectTotalEntry = (state) => state.condition.totalEntry
export const selectConditionDetail = (state) => state.condition.conditionDetail

export default conditionSlice.reducer
 