import { createSlice } from "@reduxjs/toolkit"


export const mcoinSlice = createSlice({
  name: "mcoin",
  initialState: {
    mcoinList: [],     
    mcoinDetail: {},  
    mcoinTotal: {},
    totalEntry: 0 
     
  }, 
  reducers: {
    setMcoinList: (state, action) => {
      state.mcoinList = action.payload
    },
    setMcoinTotal: (state, action) => {
      state.mcoinTotal = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.mcoinDetail = action.payload
    }

  }
})

export const {
  setMcoinList,
  setTotalEntry,
  setMcoinTotal
} = mcoinSlice.actions

export const selectMcoinList = (state) => state.mcoin.mcoinList
export const selectTotalEntry = (state) => state.mcoin.totalEntry
export const selectMcoinTotal = (state) => state.mcoin.mcoinTotal
export const selectMcoinDetail = (state) => state.mcoin.mcoinDetail

export default mcoinSlice.reducer
