import { createSlice } from "@reduxjs/toolkit"

export const donationSlice = createSlice({
  name: "donation",
  initialState: {
    donationList: [], 
    categoryList: [],   
    exportList: [],  
    donationDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    totalEntryDetail: 0,
    filterTerm: 'Active'  
  }, 
  reducers: {
    setDonationList: (state, action) => { 
      state.donationList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setTotalEntryDetail: (state, action) => {
      state.totalEntryDetail = action.payload
    },
    setSingleData: (state, action) => {
      state.donationDetail = action.payload
    }

  }
})

export const {
  setDonationList,
  setFreshList,
  setExportList,
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setTotalEntryDetail,
  setFilteredList
} = donationSlice.actions

export const selectDonationList = (state) => state.donation.donationList
export const selectFreshData = (state) => state.donation.isFreshData
export const selectCategoryList = (state) => state.donation.categoryList
export const selectExportList = (state) => state.donation.exportList
export const selectFilterTerm = (state) => state.donation.filterTerm
export const selectTotalEntry = (state) => state.donation.totalEntry
export const selectTotalEntryDetail = (state) => state.donation.totalEntryDetail
export const selectDonationDetail = (state) => state.donation.donationDetail

export default donationSlice.reducer
 