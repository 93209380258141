import { createSlice } from "@reduxjs/toolkit"

export const noticeSlice = createSlice({
  name: "notice",
  initialState: {
    noticeList: [],   
    eventDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setNoticeList: (state, action) => { 
      state.noticeList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.noticeDetail = action.payload
    }

  }
})

export const {
  setNoticeList,
  setFreshList,
  setExportList,
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = noticeSlice.actions

export const selectNoticeList = (state) => state.notice.noticeList
export const selectFreshData = (state) => state.notice.isFreshData
export const selectCategoryList = (state) => state.notice.categoryList
export const selectExportList = (state) => state.notice.exportList
export const selectFilterTerm = (state) => state.notice.filterTerm
export const selectTotalEntry = (state) => state.notice.totalEntry
export const selectNoticeDetail = (state) => state.notice.noticeDetail

export default noticeSlice.reducer
 