import { createSlice } from "@reduxjs/toolkit"

export const stringSlice = createSlice({
  name: "string",
  initialState: {
    stringList: [],  
    stringDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setStringList: (state, action) => { 
      state.stringList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.stringDetail = action.payload
    }

  }
})

export const {
  setStringList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} =  stringSlice.actions

export const selectStringList = (state) => state.string.stringList
export const selectFreshData = (state) => state.string.isFreshData
export const selectFilterTerm = (state) => state.string.filterTerm
export const selectTotalEntry = (state) => state.string.totalEntry
export const selectStringDetail = (state) => state.string.stringDetail

export default stringSlice.reducer
 