import { createSlice } from "@reduxjs/toolkit"

export const treedataSlice = createSlice({
  name: "treedata",
  initialState: {
    treedataList: [], 
    categoryList: [],   
    exportList: [],  
    treedataDetail: {},  
    isFreshData: false,
    totalEntry: 0,   
    filterTerm: 'Active'  
  }, 
  reducers: {
    setTreedataList: (state, action) => { 
      state.treedataList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.treedataDetail = action.payload
    }

  }
})

export const {
  setTreedataList,
  setFreshList,
  setExportList,
  setCategoryList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = treedataSlice.actions

export const selectTreeDataList = (state) => state.treedata.treedataList
export const selectFreshData = (state) => state.treedata.isFreshData
export const selectCategoryList = (state) => state.treedata.categoryList
export const selectExportList = (state) => state.treedata.exportList
export const selectFilterTerm = (state) => state.treedata.filterTerm
export const selectTotalEntry = (state) => state.treedata.totalEntry
export const selectTreeDataDetail = (state) => state.treedata.treedataDetail

export default treedataSlice.reducer
 