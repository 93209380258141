// otpSlice.js
import { createSlice } from "@reduxjs/toolkit"

export const otpSlice = createSlice({
  name: "otp",
  initialState: {
    phoneNumber: null,
    otpNumber: null
  },
  reducers: {
    setNumber: (state, action) => {
      state.phoneNumber = action.payload
    },
    setotpNumber: (state, action) => {
      state.otpNumber = action.payload
    }
  }
})

export const { setNumber, setotpNumber } = otpSlice.actions

export const selectPhoneNumber = (state) => state.otp.phoneNumber
export const selectOtpNumber = (state) => state.otp.otpNumber

export default otpSlice.reducer
